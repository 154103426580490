import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ComingSoon from './components/ComingSoon';
import ContactPage from './components/ContactPage';
import styled, { keyframes } from 'styled-components';
import ONDCInfo from './components/ONDCInfo';
import logo from './components/logo.png';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Footer = styled.footer`
  padding: 20px;
  text-align: center;
  background: #f0f0f0;
`;

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/" element={<ComingSoon />}/>
        <Route path="/ondcinfo" element={<ONDCInfo />}/>
      </Routes>
      <Footer>
        <Link to="/">Home</Link> | <Link to="/ondcinfo">ONDC</Link> | <Link to="/contact">Contact Us</Link>
      </Footer>
    </Router>
  );
};

export default App;
