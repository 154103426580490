import React, { useState } from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
`;

const Select = styled.select`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 318px;
`;

const RadioButtonLabel = styled.label`
  margin: 0 10px;
  font-size: 1rem;
`;

const RadioButton = styled.input`
  margin-right: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`;

const ContactForm: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [category, setCategory] = useState('');
  const [role, setRole] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    alert(`Thank you, ${name}, for your interest! We will contact you shortly.`);
    // Ideally, here you would handle the form submission to the backend.
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h2>Contact Us</h2>
      <Input type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
      <Input type="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <Select value={category} onChange={(e) => setCategory(e.target.value)}>
        <option value="">Select Product Category</option>
        <option value="electronics">Electronics</option>
        <option value="apparel">Apparel</option>
        <option value="home_garden">Home & Garden</option>
        <option value="beauty_health">Beauty & Health</option>
        <option value="sports">Sports</option>
        <option value="toys">Toys</option>
        <option value="automotive">Automotive</option>
      </Select>
      <div>
        <RadioButtonLabel>
          <RadioButton type="radio" name="role" value="buyer" checked={role === 'buyer'} onChange={(e) => setRole(e.target.value)} />
          Buyer
        </RadioButtonLabel>
        <RadioButtonLabel>
          <RadioButton type="radio" name="role" value="seller" checked={role === 'seller'} onChange={(e) => setRole(e.target.value)} />
          Seller
        </RadioButtonLabel>
        <RadioButtonLabel>
          <RadioButton type="radio" name="role" value="other" checked={role === 'other'} onChange={(e) => setRole(e.target.value)} />
          Other
        </RadioButtonLabel>
      </div>
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default ContactForm;
