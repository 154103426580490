import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(120deg, #89f7fe, #66a6ff); // You can replace this with your brand's gradient or solid color.
`;

const PageContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

const Header = styled.h1`
  color: #333;
`;

const Section = styled.section`
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #0cf;
`;

const Subheader = styled.h2`
  color: #555;
`;

const Paragraph = styled.p`
  text-align: left;
  line-height: 1.5;
`;

const ONDCInfo: React.FC = () => {
  return (
    <Container>
      <Header>Why ONDC Is a Game Changer</Header>
      <Section>
        <Subheader>Open and Inclusive</Subheader>
        <Paragraph>
          ONDC is not just another e-commerce platform but a network that aims to open up the digital commerce
          sector to a large number of buyers and sellers by democratizing the processes involved. By allowing
          multiple sellers and logistics providers to participate, it reduces the dominance of single players
          and helps smaller businesses thrive.
        </Paragraph>
      </Section>
      <Section>
        <Subheader>Boost to Small and Medium Enterprises</Subheader>
        <Paragraph>
          Through ONDC, small and medium-sized enterprises (SMEs) get a significant boost by gaining access
          to a national platform. This inclusivity helps local vendors and manufacturers reach a broader
          audience, empowering regions and boosting local economies.
        </Paragraph>
      </Section>
      <Section>
        <Subheader>Innovation and Opportunities</Subheader>
        <Paragraph>
          ONDC paves the way for innovations in technology and business processes, offering countless
          new opportunities for tech companies, service providers, and entrepreneurs to create novel
          solutions that enhance the consumer and seller experience.
        </Paragraph>
      </Section>
    </Container>
  );
};

export default ONDCInfo;
