import React from 'react';
import styled, { keyframes } from 'styled-components';
import ContactForm from './ContactForm';
import { Link } from 'react-router-dom';
import logo from './logo.png';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(120deg, #89f7fe, #66a6ff); // You can replace this with your brand's gradient or solid color.
`;

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
`;

const Logo = styled.img`
  width: 100px; // Adjust size based on your logo
  animation: ${fadeIn} 2s ease-out forwards;
`;

const ContactPage: React.FC = () => {
  return (
    <Container>
      <div><Link to="/"><Logo src={logo} alt="India Bazaar Now Logo" /></Link></div>
      <h1>Contact Us</h1>
      <p>We're excited to hear from you! Fill out the form below.</p>
      <ContactForm />
    </Container>
  );
};

export default ContactPage;
