import React from 'react';
import styled, { keyframes } from 'styled-components';
import logo from './logo.png'; // Make sure to place your logo in the src/components folder or update the path accordingly.
import ondclogo from './ondclogo.png';
import ContactForm from './ContactForm';
import { Link } from 'react-router-dom';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(120deg, #22a6ff, #89f7fe); // You can replace this with your brand's gradient or solid color.
`;

const Logo = styled.img`
  width: 150px; // Adjust size based on your logo
  height: auto;
  margin-bottom: 20px;
  animation: ${fadeIn} 2s ease-out forwards;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  color: #00416a; // Adjust to match your brand's primary color
  animation: ${fadeIn} 2s ease-out forwards;
  margin-bottom: 15px;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #012a4a; // Adjust to a secondary color in your brand palette
  animation: ${fadeIn} 3s ease-out forwards;
`;

const ComingSoon: React.FC = () => {
  return (
    <Container>
      <Logo src={logo} alt="India Bazaar Now Logo" />
      <Heading>Coming Soon!</Heading>
      <Subtitle>Get ready for a new way to experience e-commerce with <Link to='/ondcinfo'><img style={{height:20, marginTop:12}} src={ondclogo} alt='ONDC'/></Link>. Stay tuned!</Subtitle>
    </Container>
  );
};

export default ComingSoon;
